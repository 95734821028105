/* @flow */

import type { QuotePayment } from "shop-state/types";

import React, { useState, useContext } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { Button, Dialogue } from "@crossroads/ui-components";
import { CmsData } from "data";
import { CheckboxField } from "components/Field";
import { StoreInfoContext } from "entrypoint/shared";
import { StripeContext, StripeCardComponent, StripeButtonComponent } from "components/Stripe";
import CmsPageView from "components/CmsPageView";
import { Box, BoxHeader, BoxBody } from "components/Box";

import ArrowIcon from "icons/arrow.svg";
import AmexLogo from "icons/payment_methods/amex.svg";
import MastercardLogo from "icons/payment_methods/mastercard.svg";
import VisaLogo from "icons/payment_methods/visa.svg";
import GooglePayIcon from "icons/payment_methods/google.svg";
import MSPayIcon from "icons/payment_methods/microsoft.svg";
import ApplePayIcon from "icons/payment_methods/apple.svg";

import styles from "./styles.scss";

type Props = {
  method: ?QuotePayment,
  processing: boolean,
  loading: boolean,
  disabled: boolean,
  terms: boolean,
  verified: boolean,
};

const stripeStyles = {
  style: {
    base: {
      color: styles.textColor,
      letterSpacing: "0.025em",
      fontSize: "16px",
      "::placeholder": {
        color: styles.colorMuted,
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const PaymentMethods = ({ method, loading, processing, disabled, terms, verified }: Props) => {
  const t = useTranslate();
  const { info: { locale } } = useContext(StoreInfoContext);
  const cmsData = useData(CmsData);
  const [showTerms, setShowTerms] = useState(false);
  const { stripeMethod, setStripeMethod } = useContext(StripeContext);
  const pointsOnly = (method !== null && method !== undefined) && method.code === "free";

  const renderTerms = () => (
    <>
      {cmsData.state === "LOADED" &&
        <div className={styles.modalWrapper}>
          <Dialogue
            className={styles.modal}
            open={showTerms}
            setOpen={() => setShowTerms(false)}
          >
            <div className={styles.container}>
              <CmsPageView className={styles.cms} cmsPage={cmsData.data} />
            </div>
          </Dialogue>
        </div>
      }

      <div className={styles.terms}>
        <CheckboxField
          id="terms"
          name="terms"
          value={terms}
        >
          <span className={styles.text}>{t("CHECKOUT.I_ACCEPT_THE")}{" "}</span>
          <span
            className={styles.link}
            onClick={e => {
              e.preventDefault();
              setShowTerms(true);
            }}
          >
            {t("CHECKOUT.TERMS")}
          </span>
        </CheckboxField>
      </div>
    </>
  );

  if (locale === "sv_SE" || locale === "en_GB") {
    return (
      <Box className={styles.block}>
        <BoxHeader>
          <h2>{t("CHECKOUT.PAYMENT_TITLE")}</h2>
        </BoxHeader>
        <BoxBody>
          {renderTerms()}
          <Button
            className={styles.button}
            variant="primary"
            loading={processing}
            disabled={disabled || !terms}
            type="submit"
          >
            {t("CHECKOUT.CONFIRM_ORDER")}
          </Button>
        </BoxBody>
      </Box>
    );
  }

  if (!verified && !pointsOnly) {
    return (
      <Box className={cn(styles.block, styles.disabled)}>
        <BoxHeader>
          <h2>{t("CHECKOUT.PAYMENT_TITLE")}</h2>
        </BoxHeader>
      </Box>
    );
  }

  if (pointsOnly) {
    return (
      <Box className={styles.block}>
        <BoxHeader>
          <h2>{t("CHECKOUT.PAYMENT_TITLE")}</h2>
        </BoxHeader>
        <BoxBody>
          {renderTerms()}
          <Button
            className={styles.button}
            variant="primary"
            loading={processing}
            disabled={disabled || !terms}
            type="submit"
          >
            {t("CHECKOUT.CONFIRM_ORDER")}
          </Button>
        </BoxBody>
      </Box>
    );
  }

  return (
    <Box className={cn(styles.block, { [styles.loading]: loading })}>
      <BoxHeader>
        <h2>{t("CHECKOUT.PAYMENT_TITLE")}</h2>

        {stripeMethod &&
          <Button
            className={styles.editButton}
            variant="blank"
            size="small"
            slotLeft={<ArrowIcon className={styles.editIcon} />}
            onClick={() => setStripeMethod(null)}
          >
            <span>{t("CHECKOUT.EDIT")}</span>
          </Button>
        }
      </BoxHeader>
      <BoxBody>
        {renderTerms()}

        {stripeMethod !== "CARD" &&
          <div>
            <StripeButtonComponent
              disabled={disabled || !terms}
              processing={processing || loading}
              styles={styles}
              options={stripeStyles} />
          </div>
        }

        {stripeMethod === null &&
          <Button
            disabled={disabled || !terms}
            loading={loading}
            className={styles.button}
            variant="ghost"
            onClick={() => setStripeMethod("CARD")}
          >
            {t("PAYMENT.USE_CARD")}
          </Button>
        }

        {stripeMethod === "CARD" &&
          <StripeCardComponent
            disabled={disabled || !terms}
            processing={processing || loading}
            styles={styles}
            options={stripeStyles} />
        }

        <div className={styles.cards}>
          <div className={styles.cardsInner}>
            <MastercardLogo className={styles.mastercard} />
            <VisaLogo className={styles.visa} />
            <AmexLogo className={styles.amex} />
            <ApplePayIcon className={styles.apple} />
            <MSPayIcon className={styles.microsoft} />
            <GooglePayIcon className={styles.google} />
          </div>
        </div>
      </BoxBody>
    </Box>
  );
};

export default PaymentMethods;
